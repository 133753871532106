import { mapStateAtom } from '../models/atoms/ui_atoms';
import { authStateAtom } from '../models/atoms/auth_atoms';
import { deviceListStateAtom, selectedDeviceStateAtom, filterStateAtom } from '../models/atoms/device_atoms';
import { getRecoil, setRecoil } from "recoil-nexus";
import { deviceListInterface, deviceInterface } from '../models/interfaces/device_interfaces';



const device_link = process.env.REACT_APP_DEVICE_API_URL;

export const device_controller = {
  get_device_list: async function (callback = () => { }) {
    try {
      const filter = getRecoil(filterStateAtom);

      // Ensure filter and filter.labels are defined, this was breaking the test
      if (!filter || !filter.labels) {
        console.error('Filter or filter.labels is undefined');
        return;
      }

      const params = new URLSearchParams({
        pinned: filter.pinned?.toString() || '',
      });

      filter.labels.forEach(label => {
        params.append('labels', label);
      });

      const obj = {
        link: `${device_link}/devices?${params.toString()}`,
        object: {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${getRecoil(authStateAtom).token}`,
          }
        }
      };

      const response = await fetch(obj.link, obj.object);

      if (!response.ok) {
        console.error('Failed to fetch device list:', response.status, response.statusText);
        return;
      }

      const deviceList = await response.json() as deviceListInterface;
      setRecoil(deviceListStateAtom, deviceList);
      callback();
    } catch (error) {
      console.error('Error fetching device list:', error);
    }
  },

  select_device: async function (device_id: string, measurement_period_type: string = "day") {
    var obj = {
      link: device_link + '/device?' + new URLSearchParams({
        device_id: device_id,
        measurement_period_type: measurement_period_type,
      }),
      object: {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
        }
      }
    };
    await fetch(obj.link, obj.object)
    .then(async response => {
      const data = await response.json() as deviceInterface;
      // console.log('Response data:',data);
      return data;
    })
    .then(device => {
      // console.log('Device:', device);
      setRecoil(selectedDeviceStateAtom, device);
      return device;
    })
      .then(data => {
        setRecoil(mapStateAtom,
          {
            requested_centre: [data.longitude, data.latitude] as [number, number],
            required_update: true,
            requested_zoom: 12,
          })
      });
  },

  toggle_device_pin: function (device_id: string, callback = () => { }) {
    var obj = {
      link: device_link + '/device?' + new URLSearchParams({
        device_id: device_id,
        measurement_period_type: 'day',
      }),
      object: {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
        }
      }
    };
    fetch(obj.link, obj.object)
      .then(async response => await response.json() as deviceInterface)
      .then(device => {
        device.pinned = !device.pinned;
        var obj = {
          link: device_link + '/device',
          object: {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
              'Content-type': 'application/json'
            },
            body: JSON.stringify(
              device
            )
          }
        };
        fetch(obj.link, obj.object).then(() => { this.get_device_list(); callback(); this.select_device(device_id); });
      });
  },

  change_device_comments: function (device_id: string, comments: string, callback = () => { }) {
    var obj = {
      link: device_link + '/device?' + new URLSearchParams({
        device_id: device_id,
        measurement_period_type: 'day',
      }),
      object: {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
        }
      }
    };
    fetch(obj.link, obj.object)
      .then(async response => await response.json() as deviceInterface)
      .then(device => {
        device.comments = comments;
        var obj = {
          link: device_link + '/device',
          object: {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
              'Content-type': 'application/json'
            },
            body: JSON.stringify(
              device
            )
          }
        };
        fetch(obj.link, obj.object).then(() => { this.get_device_list(); callback(); this.select_device(device_id); });
      });
  },

  change_device_warning_level_percentage: function (device_id: string, warning_level_percentage: number, callback = () => { }) {
    var obj = {
      link: device_link + '/device?' + new URLSearchParams({
        device_id: device_id,
        measurement_period_type: 'none',
      }),
      object: {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
        }
      }
    };

    fetch(obj.link, obj.object)
      .then(async response => await response.json() as deviceInterface)
      .then(device => {
        device.warning_level_percentage = warning_level_percentage;
        var obj = {
          link: device_link + '/device',
          object: {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
              'Content-type': 'application/json'
            },
            body: JSON.stringify(
              device
            )
          }
        };
        fetch(obj.link, obj.object).then(() => { this.get_device_list(); callback(); this.select_device(device_id); });
      });
  },
}