import {
    atom
} from 'recoil';

import { mapStateInterface } from '../interfaces/ui_interfaces';

export const panelSizesAtom = atom<{
    hTop : string,
    hBottom : string,
    vLeft : string,
    vRight : string
}>({
    key : 'panelSizes',
    default : {
        hTop : '0px',
        hBottom : '0px',
        vLeft : '0px',
        vRight : '0px'
    }
})


export const navStateAtom = atom<'device-metrics' | 'accounts-page' | 'settings-page'>({
    key : 'navState',
    default : 'device-metrics'
})

export const mapStateAtom = atom<mapStateInterface>({
    key: 'mapState',
    default : {
        requested_centre : [-3, 53],
        requested_zoom : 5,
        required_update : false
    }
})