import {
    atom, selector,
} from 'recoil';

import { deviceListInterface, deviceInterface, filterStateInterface } from "../interfaces/device_interfaces";


export const deviceListStateAtom = atom<deviceListInterface>({
    key: 'deviceListState', // unique ID (with respect to other atoms/selectors)
    default: {
        devices : [],
        search_filter : {
            latitude : null,
            longitude : null,
            distance : null,
            warning_level : null,
            start_index : null,
            end_index : null,
            labels : null,
            pinned : null
        }
    }, // default value (aka initial value)
});

export const systemTypesSelector = selector({
    key: 'systemTypesSelector',
    get: ({ get }) => {
      const deviceList = get(deviceListStateAtom);
      const systemTypes = new Set(deviceList.devices.map(device => device.system_type));
      return Array.from(systemTypes);
    }
  });

export const selectedDeviceStateAtom = atom<deviceInterface|null>({
    key : 'selectedDeviceState',
    default : null,
});


export const filterStateAtom = atom<filterStateInterface>({
    key: 'filterStateAtom',
    default : {
        pinned : false,
        lowBattery: false,
        highWarningLevel: false,
        noMessages: false,
        highROC: false,
        blockage: false,
        searchText : '',
        labels : [],
    },
})


export const filteredDeviceListSelector = selector({
    key: 'filteredDeviceListSelector',
    get: ({get}) => {
        const filter = get(filterStateAtom);
        const deviceList = get(deviceListStateAtom);

        if (!filter.highWarningLevel && !filter.labels.length && !filter.lowBattery && !filter.pinned && !filter.searchText && !filter.noMessages && !filter.blockage){
            return {devices: deviceList.devices}
        }

        return {
            devices: deviceList.devices.filter(device => device.device_id.toLowerCase().includes(filter.searchText.toLowerCase()))
        .filter(device => device.pinned || !filter.pinned)
        .filter(device => device.battery_percentage < 25 || !filter.lowBattery)
        .filter(device => device.warning_level === 10 || !filter.highWarningLevel)
        .filter(device => device.labels.includes('No messages') || !filter.noMessages)
        .filter(device => device.labels.includes('blockage') || !filter.blockage)
        .filter(device => {
            var result = true;
            filter.labels.forEach(label => {
                if (!device.labels.includes(label)) {
                    result = false;
                    return result
                }
            })
            if (result) {
                return device
            }
            return false
        })
    };
      },
})