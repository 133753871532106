import React from 'react';
import { memo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { setRecoil } from "recoil-nexus";
import '../../styles/shared_styles.css';
import { css } from 'aphrodite';
import "bootstrap-icons/font/bootstrap-icons.css";
import { changeUserAtom, textAlertErrorAtom } from "../../../models/atoms/account_atoms";
import { changeCompanyDeviceAtom } from "../../../models/atoms/company_atoms";
import { account_controller } from "../../../controllers/account_controller";
import { company_controller } from "../../../controllers/company_controller";
import { SettingsPageStyles } from "../../styles/settings_page_styles";
import { button_styles } from "../../styles/reusable_styles";
import { useTranslation } from '../../../contexts/TranslationContext';
/* eslint-disable no-useless-escape */



export const AccountSettingsComponent = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const { t } = useTranslation();
    const [changeUser, setChangeUser] = useRecoilState(changeUserAtom);
    const times = useRecoilValue(changeCompanyDeviceAtom);
    const [newPassword, setNewPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [curPassword, setCurPassword] = useState('');
    const [nameValidationError, setNameValidationError] = useState('');
    const [phoneValidationError, setPhoneValidationError] = useState('');
    const [passwordValidationError, setPasswordValidationError] = useState('');
    const [matchPasswordsError, setMatchPasswordsError] = useState('');

    const handleReset = () => {
        if (newPassword.length < 12){
            return;
        }
        if (newPassword !== rePassword){
            return;
        }
        setNewPassword('');
        setRePassword('');
        setCurPassword('');
        account_controller.change_user_password(newPassword, curPassword)
            .then(response => {
                if (response.ok) {
                    alert(t("Update Successful"));
                }
            })
            .catch(error => {
                alert(t("Password Incorrect"));
            })
    }

    if (!times) {
        return <></>
    }

    return  <section id='account' className={css(SettingsPageStyles.settings_card)} onSubmit={(e) => {
            e.preventDefault();
            if (times === null) { return; }
            company_controller.modify_device_settings(times?.message_wait_time_s, times?.measurement_sleep_time_s, times?.warning_message_wait_time_s, times?.warning_measurement_sleep_time_s)
        }}>
        <h2 className={css(SettingsPageStyles.card_title)}>{t("Account")}</h2>
        <div className={css(SettingsPageStyles.settings_card_body)}>
        <section className={css(SettingsPageStyles.settings_card_sections_container)}>
        <form className={css(SettingsPageStyles.settings_card_section_container)}>
        <h3 className={css(SettingsPageStyles.card_subtitle)}>{t("Account Information")}</h3>
        <label className={css(SettingsPageStyles.card_input_label)} htmlFor="first_name">{t("First Name")}</label>
        <input className={css(SettingsPageStyles.card_input)} type="text"  name="first_name" value={changeUser == null ? '' : changeUser.first_name} 
                            onChange={(e) => {
                                setNameValidationError('');
                                if (changeUser == null) { return; }
                                setChangeUser({
                                    ...changeUser,
                                    first_name: e.target.value,
                                    change_first_name: true
                                })
                                if (!e.target.value || e.target.value.length < 2) {
                                    setNameValidationError(t('First and Last names cannot be empty and must be at least 2 characters long!'));
                                }
                                else if (!(/^[a-zA-Z]+$/.test(e.target.value)) || !(/^[a-zA-Z]+$/.test(e.target.value))) {
                                    setNameValidationError(t('Ensure that both the First and Last names exclusively consist of valid English alphabet characters!'));
                                } 
                            
                            }}/> 

        <label className={css(SettingsPageStyles.card_input_label)} htmlFor="last_name">{t("Last Name")}</label>
        <input className={css(SettingsPageStyles.card_input)} type="text" name="last_name" value={changeUser == null ? '' : changeUser.last_name} 
                            onChange={(e) => {
                                setNameValidationError("");
                                if (changeUser == null) { return; }
                                setChangeUser({
                                    ...changeUser,
                                    last_name: e.target.value,
                                    change_last_name: true
                                })
                                if (!e.target.value || e.target.value.length < 2) {
                                    setNameValidationError(t('First and Last names cannot be empty and must be at least 2 characters long!'));
                                }
                                else if (!(/^[a-zA-Z]+$/.test(e.target.value)) || !(/^[a-zA-Z]+$/.test(e.target.value))) {
                                    setNameValidationError(t('Ensure that both the First and Last names exclusively consist of valid English alphabet characters!'));
                                }
                            }}/> 
        <p className={css(SettingsPageStyles.error)}>{nameValidationError}</p>


        <label className={css(SettingsPageStyles.card_input_label)} htmlFor="email">{t("Email")}</label>
        <input className={css(SettingsPageStyles.card_input)} type="text" name="email" disabled defaultValue={changeUser == null ? '' : changeUser.email} /> 

        <label className={css(SettingsPageStyles.card_input_label)} htmlFor="phone_number">
  {t("Phone Number")}
  <br />
  {t("+country code without leading zero or spaces")}
  <br />
    {t("(e.g. +447XXXXXXXXX)")}
</label>
        <input className={css(SettingsPageStyles.card_input)} type="text" name="phone_number" defaultValue={changeUser?.phone_number} onChange={(e) => {
                                if (changeUser == null) { return; }
                                setRecoil(textAlertErrorAtom, '');
                                setPhoneValidationError('');
                                if (e.target.value.length < 1) {
                                    setPhoneValidationError("");
                                    setChangeUser({
                                        ...changeUser,
                                        phone_number: "-",
                                        change_email_phone: true
                                    })
                                }
                                else if (!(/^[0-9+]+$/).test(e.target.value) || e.target.value.length < 8) {
                                    setPhoneValidationError(t("Please provide a valid phone number!"));
                                } 
                                else {
                                    setChangeUser({
                                        ...changeUser,
                                        phone_number: e.target.value,
                                        change_email_phone: true
                                    })
                                }
                            }}/> 

        <p className={css(SettingsPageStyles.error)}>{phoneValidationError}</p>
        <button type="submit" className={((changeUser?.change_email_phone || changeUser?.change_first_name || changeUser?.change_last_name) && (!phoneValidationError && !nameValidationError) ) ? css(button_styles.main_button) : css(button_styles.main_button_disabled)}
                            style={{padding: '0.2rem 1rem', margin: '1rem 0', minWidth: '5rem'}}
                            onClick={() => {
                                if (changeUser == null || phoneValidationError || nameValidationError) { return; }
                                account_controller.modify_user_account(
                                    {
                                        email: changeUser.email,
                                        phone_number: changeUser.phone_number,
                                        first_name: changeUser.first_name,
                                        last_name: changeUser.last_name
                                    })
                            }
                            }>{t("Save")}</button>
        </form>

        <form className={css(SettingsPageStyles.settings_card_section_container)}>
        <h3 className={css(SettingsPageStyles.card_subtitle)}>{t("Change Password")}</h3>
        <label className={css(SettingsPageStyles.card_input_label)} htmlFor="">{t("Old Password")}</label>
        <input className={css(SettingsPageStyles.card_input)} type="password" onChange={(s) =>
                                setCurPassword(s.target.value)} value={curPassword}/>

        <label className={css(SettingsPageStyles.card_input_label)} htmlFor="">{t("New Password")}</label>
        <input className={css(SettingsPageStyles.card_input)} type="password" placeholder="" value={newPassword} onChange={(password) => {
                                if (password.target.value.length < 12 || !(/\d/).test(password.target.value) || !(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/).test(password.target.value)) {
                                    setPasswordValidationError(t("Password must be at least 12 characters and contain at least 1 number and 1 special character!"));
                                } 
                                else {
                                    setPasswordValidationError('');
                                }
                                if (password.target.value !== rePassword) {
                                    setMatchPasswordsError(t("Your passwords don't match"));
                                } else {
                                    setMatchPasswordsError('');
                                }
                                setNewPassword(password.target.value);
                                
                            }}/>

        <label className={css(SettingsPageStyles.card_input_label)} htmlFor="">{t("Repeat New Password")}</label>
        <input className={css(SettingsPageStyles.card_input)} type="password" value={rePassword} onChange={(e) =>
                                {
                                    if (newPassword !== e.target.value) { 
                                        setMatchPasswordsError(t("Your passwords don't match"));
                                    }
                                    else {
                                        setMatchPasswordsError('');
                                    }
                                    setRePassword(e.target.value);
                                }
                            }/>

        <p className={css(SettingsPageStyles.error)}>{passwordValidationError}</p>
        <p className={css(SettingsPageStyles.error)}>{matchPasswordsError}</p>
        <button type="submit" className={css((passwordValidationError || !curPassword || !newPassword || !rePassword) ? button_styles.main_button_disabled : button_styles.main_button)}
                            style={{padding: '0.2rem 1rem', margin: '1rem 0', minWidth: '5rem'}}
                            onClick={() => {
                                handleReset();
                            }}>{t("Save")}</button>
        </form>
        </section>

        </div>
        </section>

});