

//  @ts-ignore 
import low_battery_icon  from "../../assets/1.svg";
// @ts-ignore 
import high_warning_icon from "../../assets/2.svg";
// @ts-ignore 
import all_devices_icon from "../../assets/3.svg";
//  @ts-ignore
import high_battery_icon from "../../assets/4.svg";
//  @ts-ignore 
import missed_message_icon from "../../assets/5.svg";
//  @ts-ignore 
import weathermode_icon from "../../assets/6.svg";
//  @ts-ignore 
import fill_percentage_icon from "../../assets/7.svg";
/* eslint-disable no-useless-escape */
import '../../App.css';
import { css } from 'aphrodite';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../styles/shared_styles.css';
import {
  useRecoilValue,
} from 'recoil';
import { deviceListStateAtom } from "../../models/atoms/device_atoms";
import { calculateAverageBattery, calculateAverageFillPercentage, calculateMissingMessagesDevices } from "../../utils/helper_functions";

import { fleetMetricsStyles } from "../styles/device_metrics_page_styles";
import { useState } from "react";
import React from 'react';
import { useTranslation } from "../../contexts/TranslationContext";

const blind_distance = 300;

export const FleetMetrics = (_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const [showFleetInfo1, setShowFleetInfo1] = useState('');
    const [showFleetInfo2, setShowFleetInfo2] = useState('');
    const [showFleetInfo3, setShowFleetInfo3] = useState('');
    const [showFleetInfo4, setShowFleetInfo4] = useState('');
    const [showFleetInfo5, setShowFleetInfo5] = useState('');
    const [showFleetInfo6, setShowFleetInfo6] = useState('');
    const [showFleetInfo7, setShowFleetInfo7] = useState('');
    const {t} = useTranslation();
    const totalDevices = useRecoilValue(deviceListStateAtom);
  
    const [fleetScrollPosition, setFleetScrollPosition] = useState(0);
  
    const handleFleetMetricsScroll = (e: React.UIEvent) => {
      const target = e.target as HTMLTextAreaElement;
      setFleetScrollPosition(target.scrollTop);
    }
  
  
    return <div data-testid="Fleet Metrics Component" onMouseOut={() => {
      setShowFleetInfo1('');
      setShowFleetInfo2('');
      setShowFleetInfo3('');
      setShowFleetInfo4('');
      setShowFleetInfo5('');
      setShowFleetInfo6('');
      setShowFleetInfo7('');
    }}>
        {/* @ts-ignore */}
      <p className={css(fleetMetricsStyles.hover_info)} style={{display: showFleetInfo1 === 'all_devices_icon' ? 'block' : 'none', position: 'absolute', top: `${70 - fleetScrollPosition}px`}}>{t('Total Devices Deployed')}</p>
      <p className={css(fleetMetricsStyles.hover_info)} style={{display: showFleetInfo2 === 'high_warning' ? 'block' : 'none', position: 'absolute', top: `${110 - fleetScrollPosition}px`}}>{t('High Warning Devices')}</p>
      <p className={css(fleetMetricsStyles.hover_info)} style={{display: showFleetInfo3 === 'low_battery' ? 'block' : 'none', position: 'absolute', top: `${160 - fleetScrollPosition}px`}}>{t('Low Battery Devices')}</p>
      <p className={css(fleetMetricsStyles.hover_info)} style={{display: showFleetInfo4 === 'missing_messages' ? 'block' : 'none', position: 'absolute', top: `${200 - fleetScrollPosition}px`}}>{t('Devices With Missed Transmissions')}</p>
      <p className={css(fleetMetricsStyles.hover_info)} style={{display: showFleetInfo5 === 'weathermode_devices' ? 'block' : 'none', position: 'absolute', top: `${250 - fleetScrollPosition}px`}}>{t('Devices in Weather Mode')}</p>
      <p className={css(fleetMetricsStyles.hover_info)} style={{display: showFleetInfo6 === 'average_battery' ? 'block' : 'none', position: 'absolute', top: `${300 - fleetScrollPosition}px`}}>{t('Average Battery Percentage')}</p>
      <p className={css(fleetMetricsStyles.hover_info)} style={{display: showFleetInfo7 === 'average_fill' ? 'block' : 'none', position: 'absolute', top: `${345 - fleetScrollPosition}px`}}>{t('Average Fill Percentage')}</p>
  
      <div className={css(fleetMetricsStyles.fleet_metrics_container)} onScroll={(e) => handleFleetMetricsScroll(e)}>
        <p style={{margin: 7, fontWeight: 500}}>{t('Summary')}</p>
  
        <div className={css(fleetMetricsStyles.fleet_metrics_icon_value_container)}
            onMouseOver={() => {
              if (!showFleetInfo1) {
                setShowFleetInfo1("all_devices_icon")}}} 
                
              onMouseOut={() => {
                  if (showFleetInfo1){
                    setShowFleetInfo1('');
                  }
                  }
                  }
        >
        <img src={all_devices_icon} alt="" className={css(fleetMetricsStyles.fleet_metrics_icon)} />
        <p className={css(fleetMetricsStyles.fleet_metrics_value)}> {totalDevices.devices.length} </p>
        </div>
  
        <div className={css(fleetMetricsStyles.fleet_metrics_icon_value_container)}
        onMouseOver={() => {
          if (!showFleetInfo2) {
            setShowFleetInfo2("high_warning")}}} 
            
          onMouseOut={() => {
              if (showFleetInfo2){
                setShowFleetInfo2('');
              }
              }
              }
        >
        <img src={high_warning_icon} alt="" className={css(fleetMetricsStyles.fleet_metrics_icon)} />
        <p className={css(fleetMetricsStyles.fleet_metrics_value)}> {totalDevices.devices.filter(device => device.warning_level === 10).length} </p>
        </div>
  
        <div className={css(fleetMetricsStyles.fleet_metrics_icon_value_container)}
        onMouseOver={() => {
          if (!showFleetInfo3) {
            setShowFleetInfo3("low_battery")}}} 
            
          onMouseOut={() => {
              if (showFleetInfo3){
                setShowFleetInfo3('');
              }
              }
              }
        >
        <img src={low_battery_icon} alt="" className={css(fleetMetricsStyles.fleet_metrics_icon)} />
        <p className={css(fleetMetricsStyles.fleet_metrics_value)}> {totalDevices.devices.filter(device => device.battery_percentage < 25).length} </p>
        </div>
  
        <div className={css(fleetMetricsStyles.fleet_metrics_icon_value_container)}
        onMouseOver={() => {
          if (!showFleetInfo4) {
            setShowFleetInfo4("missing_messages")}}} 
            
          onMouseOut={() => {
              if (showFleetInfo4){
                setShowFleetInfo4('');
              }
              }
              }
        >
        <img src={missed_message_icon} alt="" className={css(fleetMetricsStyles.fleet_metrics_icon)} />
        <p className={css(fleetMetricsStyles.fleet_metrics_value)}> {calculateMissingMessagesDevices(totalDevices.devices)} </p>
        </div>
  
        <div className={css(fleetMetricsStyles.fleet_metrics_icon_value_container)}
        onMouseOver={() => {
          if (!showFleetInfo5) {
            setShowFleetInfo5("weathermode_devices")}}} 
            
          onMouseOut={() => {
              if (showFleetInfo5){
                setShowFleetInfo5('');
              }
              }
              }
        >
        <img src={weathermode_icon} alt="" className={css(fleetMetricsStyles.fleet_metrics_icon)} />
        <p className={css(fleetMetricsStyles.fleet_metrics_value)}> {totalDevices.devices.filter(device => device.device_weathermode_on === true).length} </p>
        </div>
  
        <div className={css(fleetMetricsStyles.fleet_metrics_icon_value_container)}
        onMouseOver={() => {
          if (!showFleetInfo6) {
            setShowFleetInfo6("average_battery")}}} 
            
          onMouseOut={() => {
              if (showFleetInfo6){
                setShowFleetInfo6('');
              }
              }
              }
        >
        <img src={high_battery_icon} alt="" className={css(fleetMetricsStyles.fleet_metrics_icon)} />
        <p className={css(fleetMetricsStyles.fleet_metrics_value)}> {calculateAverageBattery(totalDevices.devices)} </p>
        </div>
  
        <div className={css(fleetMetricsStyles.fleet_metrics_icon_value_container)}
        onMouseOver={() => {
          if (!showFleetInfo7) {
            setShowFleetInfo7("average_fill")}}} 
            
          onMouseOut={() => {
              if (showFleetInfo7){
                setShowFleetInfo7('');
              }
              }
              }
        >
        <img src={fill_percentage_icon} alt="" className={css(fleetMetricsStyles.fleet_metrics_icon)} />
        <p className={css(fleetMetricsStyles.fleet_metrics_value)}> {calculateAverageFillPercentage(totalDevices.devices, blind_distance)} </p>
        </div>
  
      </div>
     </div>

}