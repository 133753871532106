import React from 'react';
import { memo, useState } from "react";
import { useRecoilValue } from "recoil";
import '../../styles/shared_styles.css';
import { css } from 'aphrodite';
import { setRecoil } from "recoil-nexus";
//  @ts-ignore
import cross_icon from "../../../assets/cross-icon.svg";
import "bootstrap-icons/font/bootstrap-icons.css";
import { labelsAtom, changeCompanyDeviceAtom} from "../../../models/atoms/company_atoms";
import { company_controller } from "../../../controllers/company_controller";
import { SettingsPageStyles } from "../../styles/settings_page_styles";
import { button_styles, label_styles } from "../../styles/reusable_styles";
import { deleteLabelModalAtom } from "../../../models/atoms/company_atoms";
import {
    Button,
     FormGroup, Label,
    Modal, ModalHeader, ModalBody, Form,
  } from "reactstrap";
import { systemTypesSelector } from '../../../models/atoms/device_atoms';
import { useTranslation } from '../../../contexts/TranslationContext'; // Import the translation context
/* eslint-disable no-useless-escape */

export const LabelSettingsComponent = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const { t } = useTranslation(); // Use the translation hook
    const company = useRecoilValue(labelsAtom);
    const times = useRecoilValue(changeCompanyDeviceAtom);
    const [newLabelText, setNewLabelText] = useState('');
    const [labelValidationError, setLabelValidationError] = useState('');
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
    const systemTypes = useRecoilValue(systemTypesSelector)

    const saveLabel = () => {
        const button = document.getElementById("save_label");
        if (button) {
            button.click();
        }
    }

    if (!times) {
        return <></>
    }

    return <form id='label' className={css(SettingsPageStyles.settings_card)} onSubmit={(e) => {
        e.preventDefault();
        if (newLabelText === t("No messages") || newLabelText === t("Low battery") || newLabelText === t("Weather mode")){
            setLabelValidationError(t('Label not allowed!'))
            return
        }
        if (!(/^(?=.*[^\s])[a-zA-Z0-9\s.,\/\-_!@£$%)(?+=]+$/).test(newLabelText)) {
            setLabelValidationError(t("Labels cannot be empty and can contain only letters, numbers and some special characters (- _ . / ! % ? £ $ + ,)"));
            return
         }
        company_controller.create_label(newLabelText);
        setNewLabelText("");
    }}>
        <h2 className={css(SettingsPageStyles.card_title)}>{t('Labels')}</h2>
        <div className={css(SettingsPageStyles.settings_card_body)}>
        <section className={css({...label_styles.labels_container, marginBottom: '1rem'})}>
            {systemTypes.map(label => {
                if (company.labels.includes(label)) {
                    return (
                        <div className={css(label_styles.label)} key={label}>
                            <p style={{margin: 0}}>{label}</p>
                        </div>
                    );
                }
                return null;
            })}
            {
                company.labels.filter(label => !systemTypes.includes(label)).slice().sort((label_lower, label_upper) => {
                    return label_lower.toLowerCase() < label_upper.toLowerCase() ? -1 : 1;
                }).map(label => {
                    return <div className={css(label_styles.label)} key={label}>
                        <p style={{margin: 0}}>{label}</p>
                        <img alt={t('cross icon')} src={cross_icon} className={css(button_styles.remove_label_button)} onClick={() => { setRecoil(deleteLabelModalAtom, label) }} />
                    </div>
                })
            }
            </section>

            <input className={css({...SettingsPageStyles.card_input, minWidth: '70%'})} placeholder={t("Create new label")} value={newLabelText} onChange={(event) => {
                             setLabelValidationError('');
                             setNewLabelText(event.target.value); 
                            }}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    saveLabel();
                                }
                            }}
                        ></input>
            <button className={(newLabelText && !labelValidationError) ? css(button_styles.main_button) : css(button_styles.main_button_disabled)} style={{padding: '0.2rem 1rem', margin: '1rem', minWidth: '5rem'}}>{t('Save')}</button>
            <p className={css(SettingsPageStyles.error)}>{labelValidationError}</p>
            <Modal isOpen={isSaveModalOpen} toggle={() => setIsSaveModalOpen(!isSaveModalOpen)}>
    <ModalHeader toggle={() => setIsSaveModalOpen(false)}>{t('Confirm Save')}</ModalHeader>
    <ModalBody>
        <Form onSubmit={(e) => {
            e.preventDefault();
            if (!labelValidationError && newLabelText) {
                company_controller.create_label(newLabelText);
                setNewLabelText('');
                setIsSaveModalOpen(false); // Close modal after save
            }
        }}>
            <FormGroup>
                <Label for="confirmSave">{t("Are you sure you want to save these settings?")}</Label>
                <Button color="primary" type="submit">{t('Confirm')}</Button>{' '}
                <Button color="secondary" onClick={() => setIsSaveModalOpen(false)}>{t('Cancel')}</Button>
            </FormGroup>
        </Form>
    </ModalBody>
</Modal>

            
        </div>
    </form>

})
