const max_signal_quality = 34;
const max_error_rate = 97;

const max_signal_quality_percentage = 75;
const max_error_rate_percentage = 15;

// Function to calculate signal strength percentage based on received string "+CESQ: 99,99,255,255,27,65"
export const getSignalStrengthPercentage = (signal_strength: string): number => {
    const match = signal_strength.match(/\+CESQ: \d+,\d+,\d+,\d+,(\d+),(\d+)/);
    const signal_quality = match ? Number(match[1]) : NaN;
    const error_rate = match ? Number(match[2]) : NaN;

    if (isNaN(signal_quality) || isNaN(error_rate)) {
        return NaN;
    }

    const signal_quality_percentage = (signal_quality / max_signal_quality) * max_signal_quality_percentage;
    const error_rate_percentage = (error_rate / max_error_rate) * max_error_rate_percentage;

    return Math.floor(signal_quality_percentage + error_rate_percentage);
};

export const getSignalStrengthMapping = (percentage: number): string[] => {
    if (percentage < 25) {
        return ['Bad', '#cc3300'];
    } else if (percentage >= 25 && percentage < 75) {
        return ['Good', '#dfdf07'];
    } else if (percentage >= 75 && percentage <= 100) {
        return ['Excellent', '#00cc00'];
    } else {
        return ['Error', '#cc3300'];
    }
};

// Combined function to get signal strength value
export const get_signal_strength_value = (signal: string): [string, number, string] => {
    const percentage = getSignalStrengthPercentage(signal);

    if (isNaN(percentage)) {
        return ['Error', 0, '#cc3300'];
    }

    const [label, color] = getSignalStrengthMapping(percentage);
    return [label, percentage, color];
};



export const unix_timestamp_to_date_time = (unixTimestamp : number, useTime: boolean, useDate: boolean, chart_time: boolean) => {
  const options : Intl.DateTimeFormatOptions = {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      year: useDate ? 'numeric' : undefined,
      month: useDate ? 'short' : undefined,
      day: useDate ? 'numeric' : undefined,
      hour: useTime ? '2-digit' : undefined,
      minute: useTime ? '2-digit' : undefined,
      second: useTime ? '2-digit' : undefined,
      hour12: false
    };
  const milliseconds = unixTimestamp * 1000;
  const date = new Date(milliseconds);
  const localDateTimeString = date.toLocaleString('en-US', options);
  return localDateTimeString.replace(/,|at| AM| PM/g, '')
};


export function toHour(s : number) : string {
    let result = s / 3600;
    if (result.toFixed(1).includes(".0")){
      return Math.floor(result).toString()
    }
    else {
      return result.toFixed(1)
    }
    
}

export function toDays(s: number): string {
  let result = s / (3600 * 24);
  if (result.toFixed(1).includes(".0")) {
      return Math.floor(result).toString();
  } else {
      return result.toFixed(1);
  }
}

export function toMinute(s : number) : string {
    let result = s / 60;
    if (result.toFixed(1).includes(".0")){
      return Math.floor(result).toString()
    }
    else {
      return result.toFixed(1)
    }
}


export const calculateAverageFillPercentage = (devices_list : any, blind_distance : number) : number => {
    if (!devices_list.length) {
        return 0
    }
    
    const fill_percentages_list = devices_list.map((device : any) => {
      if (Math.round((device.current_level_percentage * device.max_distance_mm)/ (device.max_distance_mm - blind_distance)) <= 100) {
        return Math.round((device.current_level_percentage * device.max_distance_mm)/ (device.max_distance_mm - blind_distance))
      }
      else {
        return 100
      }
    });
    return Math.floor((fill_percentages_list.reduce((sum : number, a : number) => sum + a, 0)) / devices_list.length);
  }

export  const calculateAverageBattery = (devices_list : any) : number => {
    if (!devices_list.length) {
        return 0
    }
    const battery_percentages_list = devices_list.map((device : any) => device.battery_percentage);
    return Math.floor((battery_percentages_list.reduce((sum : number, a : number) => sum + a, 0)) / devices_list.length);
  }


export const calculateMissingMessagesDevices = (device_list : any) : number => {
    let result = 0
    if (!device_list.length) {
        return result
    }

    device_list.forEach((device : any) => {
      if (device.session_missed_transmissions.length > 0) {
        result += 1
      }
      
    });

    

  return result
  }