//  @ts-ignore
import mhm_logo from "../../../assets/logo-mhm.png";
//  @ts-ignore
import cross_icon from "../../../assets/cross-icon.svg";
/* eslint-disable no-useless-escape */
import '../../styles/shared_styles.css';
import '../../../App.css';
import { css } from 'aphrodite';
import "bootstrap-icons/font/bootstrap-icons.css";
import {
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { companyWeathermodeSettingsAtom, companyROCSettingsAtom, companyLowPowerSettingsAtom, labelsAtom } from "../../../models/atoms/company_atoms";
import { selectedDeviceStateAtom } from "../../../models/atoms/device_atoms";
import { get_signal_strength_value, unix_timestamp_to_date_time } from "../../../utils/helper_functions";
import { button_styles, label_styles } from "../../styles/reusable_styles";
import { deviceInfoPanelStyles } from "../../styles/device_metrics_page_styles";
import { useState, memo, useEffect, useRef } from "react";
import { company_controller } from "../../../controllers/company_controller";
import { device_controller } from "../../../controllers/device_controller";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import React from 'react';
import { chartDataInterface, rainDataInterface } from "../../../models/interfaces/device_interfaces";
import { useTranslation } from '../../../contexts/TranslationContext'; // Import the useTranslation hook

const image_url = process.env.REACT_APP_DEVICE_API_URL + '/image/';

export const DeviceInfoPanel = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const { t } = useTranslation(); // Use the translation hook
  const selectedDevice = useRecoilValue(selectedDeviceStateAtom);
  const company = useRecoilValue(labelsAtom);
  const setSelectedDevice = useSetRecoilState(selectedDeviceStateAtom);
  const [commentChanges, setCommentChanged] = useState(false);
  const [warningLevelChanged, setWarningLevelChanged] = useState(false);
  const [warningLevel, setWarningLevel] = useState(selectedDevice?.warning_level_percentage);
  const [commentText, setCommentText] = useState('');
  const [deviceID, setDeviceID] = useState('');
  const [warningThresholdError, setWarningThresholdError] = useState('');
  const text_area = useRef(null);
  const [labelsDropdown, setLabelsDropdown] = useState(false);
  const alertLabels = [t("Low battery"), t("No messages"), t("High ROC"), t("Blockage"), t("High Level")];
  const specialLabels = ["StormWater", "Sewage", "Combined", "Unknown"];
  const companyLowPowerSettings = useRecoilValue(companyLowPowerSettingsAtom);
  const companyWeathermodeSettings = useRecoilValue(companyWeathermodeSettingsAtom);
  const companyROCSettings = useRecoilValue(companyROCSettingsAtom);

  useEffect(() => {
    company_controller.get_low_power_settings();
    company_controller.get_weathermode_settings();
    company_controller.get_roc_settings();
  }, []);

  if ((selectedDevice) === null) {
    return <div className={css(deviceInfoPanelStyles.logo_container)}>
      <img src={mhm_logo} alt="logo" className={css(deviceInfoPanelStyles.logo)} />
    </div>
  }

  if (!(deviceID === selectedDevice.device_id)) {
    setDeviceID(selectedDevice.device_id);
    setCommentText(selectedDevice.comments);
    setWarningLevel(selectedDevice?.warning_level_percentage);
    setCommentChanged(false);
    setWarningLevelChanged(false);
    console.log('companyLowPowerSettings');
  }
  else {
    console.log('companyLowPowerSettings', companyLowPowerSettings);
    console.log('selectedDevice.battery_percentage', selectedDevice.battery_percentage);
    console.log('selectedDevice.signal_strength', selectedDevice.signal_strength);
  }

  <textarea
    id='commentTextAreaBlockInfo'
    className={css(deviceInfoPanelStyles.comments)}
    value={commentText}
    onChange={(event) => {
      setCommentChanged(!(event.target.value === selectedDevice.comments));
      setCommentText(event.target.value);
    }} ></textarea>;

  const changePeriodType = (periodType: 'day' | 'week' | 'month' | 'year') => {
    device_controller.select_device(selectedDevice.device_id, periodType);
  };

  const dateStr = unix_timestamp_to_date_time(selectedDevice.creation_date, true, true, false);

  var result: chartDataInterface = { measurements: [] };
  const update_measurements_date_time_str = () => {
    selectedDevice.measurements.forEach((measurement) => {
      result.measurements.push({ 'water_level_mm': measurement.water_level_mm, 'date_time_str': unix_timestamp_to_date_time(measurement.measurement_unix_timestamp, true, true, true) });
    });
    return result;
  }

  // const update_rainfall_date_time_str = () => {
  //   var rainfallResult: rainDataInterface = { metrics: [] };
  //   if (selectedDevice.rainfall_levels) {
  //     selectedDevice.rainfall_levels.forEach((metric) => {
  //       rainfallResult.metrics.push({ 'rainfall_level_mm': metric.rainfall_level_mm, 'date_time_str': unix_timestamp_to_date_time(metric.measurement_unix_timestamp, true, true, true) });
  //     });
  //   }
  //   console.log("rainfall data:", selectedDevice.rainfall_levels); // Add this line to verify selectedDevice.rainfall_levels
  //   return rainfallResult;
  // }

  const chart_data = update_measurements_date_time_str();
  // const rainfall_data = update_rainfall_date_time_str();

  return <article data-testid="Device Info Component" className={css(deviceInfoPanelStyles.panel_container)}>
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <h1 className={css(deviceInfoPanelStyles.panel_heading)}>{t("Device ID:")} {selectedDevice.device_id} {selectedDevice.pinned ? <i className="bi-pin-fill"></i> : <i className=""></i>} </h1>
        <img style={{ width: '2.5rem' }} alt='device cross icon' src={cross_icon} onClick={() => setSelectedDevice(null)} />
      </div>
    </div>

    <section>
      <h2 className={css(deviceInfoPanelStyles.section_heading)}>{t("Device Info")}</h2>
      <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t("Location:")} </span>
        <a href={`https://www.google.com/maps/place/${selectedDevice.latitude},${selectedDevice.longitude}`} target="_blank" rel="noreferrer" style={{ display: 'inline-block' }}>
          <p className={css(deviceInfoPanelStyles.link)}>{t("Google Maps Link")}</p>
        </a></p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}><span style={{ fontWeight: 500 }}>{t("Latitude, Longitude:")}</span> {selectedDevice.latitude}, {selectedDevice.longitude}</p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}><span style={{ fontWeight: 500 }}>{t("Battery Percentage:")}</span> {selectedDevice.battery_percentage}%</p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}><span style={{ fontWeight: 500 }}>{t("Alerts:")}</span></p>

      <div className={css(label_styles.labels_container)}>
        {selectedDevice.labels.filter(label => alertLabels.includes(label)).map((label) => {
          return <div className={css(
            label_styles.label_red)}>
            <p style={{ margin: 0 }}>{label}</p>
          </div>
        })}
      </div>
      <p className={css(deviceInfoPanelStyles.disclaimer)}>{t("High level, Low battery, blockage alerts etc. will display here")}</p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}><span style={{ fontWeight: 500 }}>{t("Labels:")}</span></p>
      <div className={css(label_styles.labels_container)}>
        {specialLabels.map(label => {
          if (selectedDevice.labels.includes(label)) {
            return (
              <div className={css(label_styles.label)}>
                <p style={{ margin: 0 }}>{label}</p>
              </div>
            );
          }
          return null;
        })}
        {selectedDevice.labels.filter(label => !specialLabels.includes(label)).map(label => {
          if (label === t("Low battery") || label === t("No messages") || label === t("In Weather Mode") || alertLabels.includes(label)) {
            return null
          }
          return <div className={css(label_styles.label)}>
            <p style={{ margin: 0 }}>{label}</p>
            <img alt='label cross icon' src={cross_icon} className={css(button_styles.remove_label_button)} onClick={async () => {
              await company_controller.remove_device_label(selectedDevice.device_id, label);
              await device_controller.get_device_list(() => { device_controller.select_device(selectedDevice.device_id) });
            }} />
          </div>
        })}
      </div>
      <p className={css(deviceInfoPanelStyles.disclaimer)}>{t("Navigate to settings page to create or delete labels")}</p>
      <button className={css(button_styles.main_button)} style={{ padding: '0.5rem 1rem', margin: '0.3rem 0' }} onClick={() => setLabelsDropdown(true)}>{t("Add Label")}</button>

      <div style={{ position: 'relative' }}>
        {labelsDropdown && <div className='dropdown' style={{ top: '-3rem', left: '8rem', maxHeight: '9rem' }} onMouseLeave={() => setLabelsDropdown(false)} >
          {company.labels.length < 1 ? <p className='dropdown_item'>{t("No Labels available")}</p> :
            company.labels.slice().sort((label_lower, label_upper) => {
              return label_lower.toLowerCase() < label_upper.toLowerCase() ? -1 : 1;
            }).map((label) => {
              return <p className='dropdown_item' onClick={async () => {
                setLabelsDropdown(false);
                await company_controller.add_device_label(selectedDevice.device_id, label);
                await device_controller.get_device_list(() => { device_controller.select_device(selectedDevice.device_id) });
              }}>
                {label}
              </p>
            })}
        </div>
        }
      </div>

      <div className={css(deviceInfoPanelStyles.subsection_text)}><span style={{ fontWeight: 500 }}>{t("Notes:")}</span> </div>
      <div style={{ width: '100%', paddingRight: 7 }}>
        <textarea id='commentTextAreaBlockInfo' className={css(deviceInfoPanelStyles.comments)} value={commentText} onChange={(event) => { setCommentChanged(!(event.target.value === selectedDevice.comments)); setCommentText(event.target.value); }} ref={text_area}></textarea>
      </div>
      {commentChanges ? <button className={css(button_styles.main_button)} style={{ padding: '0.3rem 0.4rem', margin: '0.3rem 0', minWidth: '5rem' }}
        onClick={() => {
          device_controller.change_device_comments(selectedDevice.device_id, commentText);
          setCommentChanged(false);
        }}>
        {t("Save")}
      </button> : <></>}
    </section>

    <section>
      <h2 className={css(deviceInfoPanelStyles.section_heading)}>{t("Readings")}</h2>
      <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t("Max Height (mm):")}</span> {selectedDevice.backend_max_height_mm}</p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t("Fill Percentage:")}</span> {selectedDevice.current_level_percentage}%</p>

      <div>
        <p className={css(deviceInfoPanelStyles.subsection_text)}>
          <span style={{ fontWeight: 500 }}>{t("Warning Threshold: ")}</span>
          <input
            className={css(deviceInfoPanelStyles.warning_level_input)}
            value={warningLevel ? warningLevel : ""}
            onChange={(event) => {
              setWarningThresholdError('');
              if (!/^\d+$/.test(event.target.value)) {
                setWarningThresholdError(t('Please provide a valid number!'));
              }
              else if (Number(event.target.value) > 90 || (Number(event.target.value) < 10)) {
                setWarningThresholdError(t('Not in the allowable range of 10%-90%!'));
              }
              if (event.target.value.length <= 2) {
                setWarningLevel(Number(event.target.value));
                setWarningLevelChanged(true);
              }
            }}
          />%
        </p>
        <p className={css(deviceInfoPanelStyles.error_text)}>{warningThresholdError}</p>
        <p className={css(deviceInfoPanelStyles.disclaimer)}>{t('Level at which alerts are sent to subscribed users and High Warning level frequencies engage')}</p>
        <p className={css(deviceInfoPanelStyles.subsection_text)}>
          <span style={{ fontWeight: 500 }}>{t("Modes:")}</span>
        </p>
        <div className={css(label_styles.labels_container)}>
          {companyLowPowerSettings && companyLowPowerSettings.low_power_on && (
            <div className={css(label_styles.label)} style={{ backgroundColor: (selectedDevice.battery_percentage < companyLowPowerSettings.low_power_battery_threshold) ? '#9492D4' : 'transparent' }}>
              <p style={{ margin: 0, color: (selectedDevice.battery_percentage < companyLowPowerSettings.low_power_battery_threshold) ? '#FFFFFF' : '#000000' }}>{t("Low Power")}</p>
            </div>
          )}

          {companyWeathermodeSettings && companyWeathermodeSettings.weathermode_on && (
            <div className={css(label_styles.label)} style={{ backgroundColor: selectedDevice.device_weathermode_on ? '#9492D4' : 'transparent' }}>
              <p style={{ margin: 0, color: selectedDevice.device_weathermode_on ? '#FFFFFF' : '#000000' }}>{t("Weather Mode")}</p>
            </div>
          )}

          {companyROCSettings && companyROCSettings.roc_mode_on && (
            <div className={css(label_styles.label)} style={{ backgroundColor: selectedDevice.labels.includes(t('High ROC')) ? '#9492D4' : 'transparent' }}>
              <p style={{ margin: 0, color: selectedDevice.labels.includes(t('High ROC')) ? '#FFFFFF' : '#000000' }}>{t("High ROC")}</p>
            </div>
          )}

          <div className={css(label_styles.label)} style={{ backgroundColor: selectedDevice.labels.includes(t('High Level')) ? '#9492D4' : 'transparent' }}>
            <p style={{ margin: 0, color: selectedDevice.labels.includes(t('High Level')) ? '#FFFFFF' : '#000000' }}>
              {selectedDevice.labels.includes(t('High Level')) ? t('High Level') : t('Normal Level')}
            </p>
          </div>
        </div>
      </div>
      {warningLevelChanged ? <button className={!warningThresholdError ? css(button_styles.main_button) : css(button_styles.main_button_disabled)}
        style={{ padding: '0.3rem 0.4rem', margin: '0.3rem 0' }}
        onClick={() => {
          if (warningLevel && !warningThresholdError) {
            device_controller.change_device_warning_level_percentage(selectedDevice.device_id, Number(warningLevel));
            setWarningLevelChanged(false);
          }
        }}>
        {t("Save")}
      </button> : <></>}

      <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t("Water Level (mm):")}</span></p>
      <LineChart className={css(deviceInfoPanelStyles.chart)} width={450} height={300} data={chart_data.measurements}>
        <Line type="monotone" dataKey="water_level_mm" stroke="rgb(0, 0, 153)" strokeWidth="3px" />
        <CartesianGrid stroke="#ccc" />
        <XAxis dataKey="date_time_str" />
        <YAxis width={70} domain={[0, selectedDevice.backend_max_height_mm]} scale='linear'></YAxis>
        <Tooltip />
      </LineChart>

      <div>
        {[['day', t('Day')], ['week', t('Week')], ['month', t('Month')], ['year', t('Year')], ['all', t('All')]].map(([key, label]) => {
          return <button className={css(selectedDevice.measurement_period_type === key ?
            button_styles.main_button : button_styles.main_button_disabled)}
            style={{ padding: '0.3rem 0.4rem', marginRight: '0.5rem', minWidth: '5rem' }}
            onClick={() => { changePeriodType(key as 'day' | 'week' | 'month' | 'year') }}>
            {label}
          </button>
        })}
      </div>

      {/* <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t("Rainfall Level (mm):")}</span></p>
      <LineChart className={css(deviceInfoPanelStyles.chart)} width={450} height={300} data={rainfall_data.metrics}>
        <Line type="monotone" dataKey="rainfall_level_mm" stroke="rgb(0, 153, 0)" strokeWidth="3px" />
        <CartesianGrid stroke="#ccc" />
        <XAxis dataKey="date_time_str" />
        <YAxis width={70} domain={[0, Math.max(...rainfall_data.metrics.map(d => d.rainfall_level_mm))]} scale='linear'></YAxis>
        <Tooltip />
      </LineChart> */}
    </section>

    <section>
      <h2 className={css(deviceInfoPanelStyles.section_heading)}>{t("Transmissions")}</h2>
      <p className={css(deviceInfoPanelStyles.disclaimer)}>{t("Missed transmissions and next transmission are calculated based on defined normal operation settings.")}</p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}>
        <span style={{ fontWeight: 500 }}>{t("Sim ID: ")}</span>
        {selectedDevice.sim_id.replace(/["',]/g, "")}
      </p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t("Modem Mode:")}</span> {selectedDevice.modem_mode}</p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t("Next Transmission:")}</span> {unix_timestamp_to_date_time(selectedDevice.expected_next_transmission, true, true, false)}</p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t("Transmissions Missed (current session):")}</span> {selectedDevice.session_missed_transmissions.length}</p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t("Transmissions Missed (lifetime):")}</span> {selectedDevice.all_missed_transmissions.length}</p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}>
    <span style={{ fontWeight: 500 }}>{t("Installation Signal Strength: ")}</span> 
    <span style={{ fontWeight: 500, color: `${get_signal_strength_value(selectedDevice?.signal_strength)[2]}`}}>
      {get_signal_strength_value(selectedDevice?.signal_strength)[1]}% ({get_signal_strength_value(selectedDevice?.signal_strength)[0]})
    </span>
</p>
    </section>

    <section>
      <h2 className={css(deviceInfoPanelStyles.section_heading)}>{t("Installation Info")}</h2>
      <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t("Installation Date:")}</span> {dateStr}</p>
      <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t("Setup Time (hh:mm:ss):")}</span> 0{Math.floor(selectedDevice.setup_time_s / 3600)}:{Math.floor(selectedDevice.setup_time_s / 60).toString().length < 2 ? `0${Math.floor(selectedDevice.setup_time_s / 60)}` : Math.floor(selectedDevice.setup_time_s / 60)}:{((selectedDevice.setup_time_s % 60).toString().length < 2) ? (`0${(selectedDevice.setup_time_s % 60)}`) : selectedDevice.setup_time_s % 60}</p>

      <div className={css(deviceInfoPanelStyles.subsection_text)}><span style={{ fontWeight: 500 }}>{t("Installation Notes:")}</span> </div>
      <div style={{ width: '100%', paddingRight: 7 }}>
        <textarea id='commentTextAreaBlockInfo' disabled className={css(deviceInfoPanelStyles.comments)} value={selectedDevice.installation_comment} onChange={(event) => { setCommentChanged(!(event.target.value === selectedDevice.comments)); setCommentText(event.target.value); }} ref={text_area}></textarea>
      </div>

      {selectedDevice.images.length > 0 && <p className={css(deviceInfoPanelStyles.subsection_text)}><span style={{ fontWeight: 500 }}>{t("Installation Photos:")}</span></p>}
      <DeviceImagesList></DeviceImagesList>
    </section>
  </article>

})

const DeviceImagesList = (_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const selectedDevice = useRecoilValue(selectedDeviceStateAtom);
  const [currentImage, setCurrentImage] = useState('');

  useEffect(() => {
    setCurrentImage(image_url + selectedDevice?.device_id.toString() + '/' + selectedDevice?.images[0])
  }, [selectedDevice])

  if (selectedDevice === null) {
    return <></>
  }

  return <><div className={css(deviceInfoPanelStyles.gallery)}>
    {selectedDevice.images?.map(image => {
      return <>
        <img className="installation-photo" alt="installation"
          key={image_url + selectedDevice.device_id.toString() + '/' + image}
          src={image_url + selectedDevice.device_id.toString() + '/' + image}
          onClick={(e) => { setCurrentImage((e.target as HTMLInputElement).src) }} />
      </>
    })
    }
  </div>
    <div>
      {selectedDevice.images.length > 0 &&
        <img alt="selected installation" style={{
          marginLeft: 50,
          maxWidth: '70%',
          marginTop: 10,
          clear: 'both',
          borderRadius: 5
        }} key={image_url + selectedDevice.device_id.toString() + '/' + currentImage}
          src={currentImage} />
      }
    </div>
  </>

};
