import React from 'react';
import { memo, useState } from "react";
import { useRecoilState } from "recoil";
import { authStateAtom } from "../../models/atoms/auth_atoms";
import { css } from 'aphrodite';
import { account_controller } from "../../controllers/account_controller";
import { auth_controller } from "../../controllers/auth_controller";
import { device_controller } from "../../controllers/device_controller";
/* eslint-disable no-useless-escape */
//  @ts-ignore
import mhm_logo from "../../assets/logo-mhm.png";
import { login_page_styles } from "../styles/login_page_styles";
import { button_styles } from "../styles/reusable_styles";
import { setRecoil } from 'recoil-nexus';
import { navStateAtom } from '../../models/atoms/ui_atoms';
import { useTranslation } from '../../contexts/TranslationContext';



export const LoginPage = memo(props => { 
    const {t} = useTranslation(); 
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [auth, setAuth] = useRecoilState(authStateAtom);
    const [forgotEmailValidationError, setForgotEmailValidationError] = useState('');
    const [forgotPasswordForm, setForgotPasswordForm] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

// eslint-disable-next-line
    const handleLogin = () => {
        const login_button = document.getElementById("login_button");
        if (login_button) {
            login_button.click();
        }
    }

    const handleEmail = () => {
        const button = document.getElementById("email_button");
        if (button) {
            button.click();
        }
    }

    return <article className={css(login_page_styles.main_container)}>
        <img src={mhm_logo} alt="mhm logo" className={css(login_page_styles.logo)}/>
        {forgotPasswordForm ?
        <form name='forgot_password' className={css(login_page_styles.forgot_password_form)} onSubmit={(e) => {
            e.preventDefault();
            if (!email || email.length < 5 ||  !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(email)) {
                setForgotEmailValidationError(t("Please provide a valid email address!"));
            }
            else {
                account_controller.send_password_link(email, () => {
                    return setEmailSent(true);
                })
            }
    }}>
           {!emailSent && <input className={css(login_page_styles.input)} type="email" value={email} onChange={(event) => {
                            setForgotEmailValidationError('');
                            setEmail(event.target.value.toLowerCase());
                        }} placeholder={t("email")}
                            onKeyPress={e => {
                                if(e.key === 'Enter') {
                                    handleEmail();
                                }
                            }}/>}
          {emailSent && <p style={{fontSize: '1.3rem', width: '60%', margin: '2rem 0', fontWeight: '500', textAlign: 'center'}}>{t('Email sent with instructions to update password. Please note this can take up to 5 minutes and may arrive in your junk folder')}</p>}
          {forgotEmailValidationError && <p className={css(login_page_styles.validation_error)}>{forgotEmailValidationError}</p>}
          <button className={css(login_page_styles.forgot_password_button)} onClick={() => setForgotPasswordForm(false)}>{t('Return to Login')}</button>
           {!emailSent && <button className={css(button_styles.main_button)} style={{padding: '0.5rem 1rem', margin: '1rem 0.5rem'}}>{t('Send me an email to reset password')}</button>}
        </form>
        :
        <form className={css(login_page_styles.login_form)} onSubmit={(e) => {
            e.preventDefault();
            auth_controller.get_auth_key(email, password, () => {
            setRecoil(navStateAtom, 'device-metrics');
            device_controller.get_device_list(); account_controller.get_account_list()
            });
        }}>
          <input className={css(login_page_styles.input)} 
           value={email} 
           onChange={(event) => {
            setAuth({...auth, showInvalidCredWarning: false});
            setEmail(event.target.value.toLowerCase())}}
           placeholder={t("email")}
          />
          <input className={css(login_page_styles.input)} type="password"
          value={password} 
          onChange={(event) => {
            setAuth({...auth, showInvalidCredWarning: false});
            setPassword(event.target.value)}}
          placeholder={t("password")}
          />
          {auth.showInvalidCredWarning && <p className={css(login_page_styles.validation_error)}>{t('Invalid Credentials!')}</p>}
          <button type='button' className={css(login_page_styles.forgot_password_button)} onClick={(e) =>{ 
            e.preventDefault(); 
            setForgotPasswordForm(true);
          }}>{t('Forgot Password ')}</button>
          <button type='submit' className={css(button_styles.main_button)} style={{padding: '0.5rem 2rem', margin: '1rem 0'}}>{t('Login ')}</button>
        </form>
        }
    </article>
})
