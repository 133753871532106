import '../../../App.css';
import { css } from 'aphrodite';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../../styles/shared_styles.css';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import { mapStateAtom } from '../../../models/atoms/ui_atoms';
import { labelsAtom } from "../../../models/atoms/company_atoms";
import { selectedDeviceStateAtom, filterStateAtom, filteredDeviceListSelector, systemTypesSelector } from "../../../models/atoms/device_atoms";
import { getRecoil, setRecoil } from "recoil-nexus";
// @ts-ignore
import sort_icon from "../../../assets/sort-icon.svg"
import { deviceListComponentStyles} from '../../styles/device_metrics_page_styles';
import { useState, memo } from "react";
import { device_controller } from "../../../controllers/device_controller";
import React from 'react';
import { button_styles, label_styles } from "../../styles/reusable_styles";
import { useTranslation } from '../../../contexts/TranslationContext'; // Import the useTranslation hook

export const DeviceListComponent = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const { t } = useTranslation(); // Use the translation hook
  const company = useRecoilValue(labelsAtom);
  const selectedDevice = useRecoilValue(selectedDeviceStateAtom);
  const [filter, setFilterState] = useRecoilState(filterStateAtom);
  const filteredDevices = useRecoilValue(filteredDeviceListSelector);
  const resetFilter = useResetRecoilState(filterStateAtom);
  const [filterDropdown, setFilterDropdown] = useState(false);
  const setSelectedDevice = useSetRecoilState(selectedDeviceStateAtom);
  const [sortDevicesBy, setSortDevicesBy] = useState('id_asc');
  const alertLabels = [t("Low battery"), t("No messages"), t("High ROC"), t("Blockage"), t("High Level")];
  const systemTypes = useRecoilValue(systemTypesSelector);

  if (!filteredDevices.devices.length) {
    setRecoil(mapStateAtom, {
      ...getRecoil(mapStateAtom),
      required_update: true,
      requested_zoom: 5,
    });
  }

  return <article data-testid="Device List Component" className={css(deviceListComponentStyles.card)}>
    <div className={css(deviceListComponentStyles.search_filter_container)}>
      <input className={css(deviceListComponentStyles.search)} type="text" placeholder={t("Search Devices")}
          value={filter.searchText}
          onChange={(event) => setFilterState({ ...filter, searchText: event.target.value })}/>
      <div className={css(deviceListComponentStyles.filter_buttons_container)}>
        <button className={css(button_styles.main_button)} style={{width: '8rem', margin: '0 10px !important', padding: '0.4rem 0.7rem', marginRight: '0.6rem'}} onClick={() => setFilterDropdown(true)}>
        <i className="bi-filter"></i> {t("Filter")}</button>
        <div style={{position: 'absolute'}}>
      {filterDropdown && <div className='dropdown' style={{top: '4.2rem', left: '7.5rem', maxHeight: '11rem', overflow: 'auto'}} onMouseLeave={() => setFilterDropdown(false)} >
        <p className={filter.pinned ? 'applied_dropdown_item' : 'dropdown_item'} onClick={async () => 
                {
                setSelectedDevice(null);
                setFilterState({ ...filter, pinned: !filter.pinned });
                await device_controller.get_device_list();
                setRecoil(mapStateAtom, {
                  ...getRecoil(mapStateAtom),
                  required_update: true,
                  requested_zoom: 5,
                });
              }
                }> {t("Pinned")} </p>
        <p className={filter.lowBattery ? 'applied_dropdown_item' : 'dropdown_item'} onClick={async () => {
  setSelectedDevice(null);
  setFilterState({ ...filter, lowBattery: !filter.lowBattery });
  await device_controller.get_device_list();
  setRecoil(mapStateAtom, {
    ...getRecoil(mapStateAtom),
    required_update: true,
    requested_zoom: 5,
  });
}}>{t("Low battery")}</p>
        <p className={filter.noMessages ? 'applied_dropdown_item' : 'dropdown_item'} onClick={async () => {
  setSelectedDevice(null);
  setFilterState({ ...filter, noMessages: !filter.noMessages });
  await device_controller.get_device_list();
  setRecoil(mapStateAtom, {
    ...getRecoil(mapStateAtom),
    required_update: true,
    requested_zoom: 5,
  });
}}>{t("No messages")}</p>
        <p className={filter.highROC ? 'applied_dropdown_item' : 'dropdown_item'} onClick={async () => {
  setSelectedDevice(null);
  setFilterState({ ...filter, highROC: !filter.highROC });
  await device_controller.get_device_list();
  setRecoil(mapStateAtom, {
    ...getRecoil(mapStateAtom),
    required_update: true,
    requested_zoom: 5,
  });
}}>{t("High ROC")}</p>
       <p className={filter.blockage ? 'applied_dropdown_item' : 'dropdown_item'} onClick={async () => {
  setSelectedDevice(null);
  setFilterState({ ...filter, blockage: !filter.blockage });
  setRecoil(mapStateAtom, {
    ...getRecoil(mapStateAtom),
    required_update: true,
    requested_zoom: 5,
  });
}}>{t("Blockage")}</p>
        <p className={filter.highWarningLevel ? 'applied_dropdown_item' : 'dropdown_item'} onClick={async () => {
                  setSelectedDevice(null);
                  setFilterState({ ...filter, highWarningLevel: !filter.highWarningLevel });
                  await device_controller.get_device_list();
                  setRecoil(mapStateAtom, {
                    ...getRecoil(mapStateAtom),
                    required_update: true,
                    requested_zoom: 5,
                  });
                }}>{t("High Level")}</p>
                {[...systemTypes, ...company.labels.filter(label => !systemTypes.includes(label) && !alertLabels.includes(label))].map((label) => { 
            return <p key={label} className={filter.labels.includes(label) ? 'applied_dropdown_item' : 'dropdown_item'} onClick={async () => {
              setFilterDropdown(false);
              setSelectedDevice(null);
              if (filter.labels.includes(label)) {
                setFilterState({
                  ...filter,
                  labels: [...filter.labels].filter((item) => item !== label)
                })
              }
              else {
                setFilterState({
                  ...filter,
                  labels: [...filter.labels, label]
                })
              }
              await device_controller.get_device_list();
              setRecoil(mapStateAtom, {
              ...getRecoil(mapStateAtom),
              required_update: true,
              requested_zoom: 5,
          });
            }}>
              {label}
            </p>
          })}
        </div>
              }
              </div>
                <button className={css(button_styles.main_button)} style={{width: '8rem', margin: '0rem 0.5rem !important', padding: '0.4rem 0.7rem'}} onClick={async () => { 
                      resetFilter();
                      setRecoil(mapStateAtom, {
                        ...getRecoil(mapStateAtom),
                        required_update: true,
                        requested_zoom: 5,
                      });
                      await device_controller.get_device_list();
                      setSelectedDevice(null);
                      }}>{t("Clear Filter")}</button>
              </div>
            </div>
        
            <ul className={css(deviceListComponentStyles.header_row)}>
          <li style={{paddingLeft: 0, paddingRight: 20, fontWeight: 600, fontSize: '1rem', width: '10rem', margin: '5px 2px 5px 0px', textAlign: 'left', maxWidth: '15rem'}} onClick={() => {
            if (sortDevicesBy === 'id_asc') {
              setSortDevicesBy('id_desc')
            } else {
              setSortDevicesBy('id_asc')
            }
          }}>{t("Device ID")}  <img src={sort_icon} alt="sort icon" style={{width: '20px', height: 'auto', marginLeft: 2}} /> </li>
              <li style={{paddingLeft: 0, paddingRight: 90, fontWeight: 600, fontSize: '1rem', width: '10rem', margin: '5px 2px', textAlign: 'center', maxWidth: '15rem'}} onClick={() => {
                if (sortDevicesBy === 'fill_asc') {
                  setSortDevicesBy('fill_desc')
                } else {
                  setSortDevicesBy('fill_asc')
                }
              }}>{t("Fill %")}  <img src={sort_icon} alt="sort icon" style={{width: '20px', height: 'auto', marginLeft: 2}} /></li>
              <li style={{paddingRight: 70, fontWeight: 600, fontSize: '1rem', width: '10rem', margin: '5px 12px', textAlign: 'center', maxWidth: '15rem'}}>{t("Labels/Alerts")}</li>
              <li style={{paddingLeft: 10, paddingRight: 0, fontWeight: 600, fontSize: '1rem', width: '10rem', margin: '5px 2px', textAlign: 'center', maxWidth: '15rem'}} onClick={() => {
                if (sortDevicesBy === 'pinned_asc') {
                  setSortDevicesBy('pinned_desc')
                } else {
                  setSortDevicesBy('pinned_asc')
                }
              }}>{t("Pinned")}  <img src={sort_icon} alt="sort icon" style={{width: '20px', height: 'auto', marginLeft: 2}} /></li>
            </ul>
            <div style={{height: '100%',overflowY: 'scroll'}}>
            <ul className={css(deviceListComponentStyles.device_list)}>
            {filteredDevices.devices.slice().sort((device_lower, device_upper) => {
                      if (sortDevicesBy === 'id_desc') {
                        return parseInt(device_lower.device_id) > parseInt(device_upper.device_id) ? -1 : 1;
                      } 
                      else if (sortDevicesBy === 'fill_asc') {
                        return device_lower.current_level_percentage < device_upper.current_level_percentage ? -1 : 1;
                      }
                      else if (sortDevicesBy === 'fill_desc') {
                        return device_lower.current_level_percentage > device_upper.current_level_percentage ? -1 : 1;
                      }
                      else if (sortDevicesBy === 'pinned_asc') {
                        return  device_lower.pinned ? -1 : 1;
                      }
                      else if (sortDevicesBy === 'pinned_desc') {
                        return !(device_lower.pinned) ? -1 : 1;
                      }
                      else {
                        return parseInt(device_lower.device_id) < parseInt(device_upper.device_id) ? -1 : 1;
                      }
                    }).map((device) => {
          
                      const selected = selectedDevice != null && (selectedDevice.device_id === device.device_id);
                      
                      return <li key={device.device_id} data-testid='device list item' className={selected ? css(deviceListComponentStyles.device_list_row_selected) : css(deviceListComponentStyles.device_list_row)} onClick={() => {
                        device_controller.select_device(device.device_id);
                        }}>
                        <div style={{textAlign: 'left', width: '6rem', fontWeight: 600, fontSize: '1rem'}}>{device.device_id}</div>
                        <div className={css(deviceListComponentStyles.device_list_row_item)} style={{justifyContent: 'center', width: '15rem', padding: '0.1rem 0.3rem', paddingLeft: '30px'}}>{device.current_level_percentage}</div>
                        <div className={css({...deviceListComponentStyles.device_list_row_item, ...label_styles.labels_container, justifyContent: 'center'})} style={{justifyContent: 'center', width: '15rem', padding: '0.1rem 0.3rem', paddingLeft: '30px'}}>
                        { device.labels.slice().sort((label_lower, label_upper) => {
                            return label_lower.toLowerCase() < label_upper.toLowerCase()  ? -1 : 1;
                          }).map((label) => {
                            if (label === t("Low battery") || label === t("No messages") || alertLabels.includes(label)) {
                              if (device.labels.includes(label)) {
                                return <div className={css(label_styles.label_red)}>{label}</div>
                              }
                            }
                            return <div className={css(label_styles.label)} >
                                    {label}</div>
                            })
                        }
                        </div>
                        <div className={css({...deviceListComponentStyles.device_list_row_item})}>
                          <button className={device.pinned ? css(button_styles.main_button) : css(button_styles.main_button_disabled)} 
                          style={{display: 'flex', justifyContent: 'center', padding: '0.1rem 0.3rem', minWidth: '5rem', marginLeft: '20px'}}
                                  onClick={() => { device_controller.toggle_device_pin(device.device_id); }}
                                >
                                  {device.pinned ? t("Y") : t("N")}
                                </button>
                          </div>
                        
                      </li>
                    })
            }
            </ul>
            </div>
          </article>
        });
