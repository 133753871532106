import React from 'react';
import { memo, useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { setRecoil } from "recoil-nexus";
import { accountListStateAtom, modifyAccountModalAtom, newAccountModalAtom, selectedAccountStateAtom } from "../../../models/atoms/account_atoms";
import { account_controller } from "../../../controllers/account_controller";
import { css } from 'aphrodite';
import '../../../App.css';
//  @ts-ignore 
import sort_icon from "../../../assets/sort-icon.svg";
/* eslint-disable no-useless-escape */
import { accountListComponentStyles } from "../../styles/accounts_page_styles";
import { button_styles, label_styles } from "../../styles/reusable_styles";
import { useTranslation } from '../../../contexts/TranslationContext';



export const AccountListComponent = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const {t} = useTranslation();
    const accountList = useRecoilValue(accountListStateAtom);
    const selectedAccount = useRecoilValue(selectedAccountStateAtom)
    const [searchText, setSearchText] = useState("");
    const [sortedByFirstName, setSortedByFirstName] = useState(false);
    const [sortedByLastName, setSortedByLastName] = useState(false);
    const [displayedAccounts, setDisplayedAccounts] = useState(accountList);

    // every time accountList changes we want to sort them by first name
    useEffect(() => {
      setDisplayedAccounts({...accountList, accounts: accountList.accounts.slice().sort((account_lower, account_upper) => {
        return account_lower.first_name < account_upper.first_name ? -1 : 1;
      })})
    }, [accountList]);
     

    const search_accounts = (searchText : string) => {
        if (!searchText.replace(/\s/g, '').length || !searchText) {
          setDisplayedAccounts({...accountList, accounts: accountList.accounts.slice().sort((account_lower, account_upper) => {
            return account_lower.first_name < account_upper.first_name ? -1 : 1;
          })})
        } else {
          setDisplayedAccounts({...accountList, accounts: accountList.accounts.filter(account => {
            if (account.first_name.toLowerCase().match(`/${searchText.toLowerCase()}`) 
            || account.last_name.toLowerCase().match(`/${searchText.toLowerCase()}/g`) 
            || `${account.first_name.toLowerCase()} ${account.last_name.toLowerCase()}`.match(`${searchText.toLowerCase()}`) 
            || account.email.toLowerCase().match(`${searchText.toLowerCase()}`)) {
              return account
            }
            return false
          })})
        }
      }
  
    const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (event : React.ChangeEvent<HTMLInputElement>) => {
          setSearchText(event.target.value);
          search_accounts(event.target.value);
      };
  
      if (accountList === undefined) {
          return <></>
      }

    return <article className={css(accountListComponentStyles.main_container)}>
        <h1 className={css(accountListComponentStyles.accounts_title)}>{t('Accounts')}</h1>
        <div className={css(accountListComponentStyles.search_add_button_container)}>
            <input className={css(accountListComponentStyles.search)} autoComplete='off' type="text" value={searchText} onChange={handleSearchChange} placeholder={t("Search Accounts")}/>
            <button className={css(button_styles.main_button)} style={{padding: '0.5rem 3rem'}} onClick={() => { setRecoil(newAccountModalAtom, true); }}>{t('Add New Account')}</button>
        </div>
         
        <div className={css(accountListComponentStyles.account_list_card)}>
        <ul className={css(accountListComponentStyles.header_row)}>
      <li className={css(accountListComponentStyles.header_row_item)} onClick={() => {
                if (sortedByFirstName) {
                  setSortedByFirstName(false);
                  setDisplayedAccounts({...displayedAccounts, accounts: displayedAccounts.accounts.slice().sort((account_lower, account_upper) => {
                    return account_lower.first_name.toLowerCase() < account_upper.first_name.toLowerCase() ? -1 : 1;
                  })})
                } else {
                  const sorted_accounts = displayedAccounts.accounts.slice().sort((account_lower, account_upper) => {
                    return account_lower.first_name.toLowerCase() > account_upper.first_name.toLowerCase() ? -1 : 1;
                  })
                  console.log('sorted accounts', sorted_accounts);
                  setDisplayedAccounts( {...displayedAccounts, accounts: sorted_accounts});
                  setSortedByFirstName(true);
              }}
            }>{t('First Name')} <img src={sort_icon} alt="sort icon" style={{width: '20px', height: 'auto', marginLeft: 2}} 
            /></li>
      <li className={css(accountListComponentStyles.header_row_item)} onClick={() => {
                if (sortedByLastName) {
                  setSortedByLastName(false);
                  setDisplayedAccounts({...displayedAccounts, accounts: displayedAccounts.accounts.slice().sort((account_lower, account_upper) => {
                    return account_lower.last_name.toLowerCase() < account_upper.last_name.toLowerCase() ? -1 : 1;
                  })})
                } else {
                  const sorted_accounts = displayedAccounts.accounts.slice().sort((account_lower, account_upper) => {
                    return account_lower.last_name.toLowerCase() > account_upper.last_name.toLowerCase() ? -1 : 1;
                  })
                  console.log('sorted accounts', sorted_accounts);
                  setDisplayedAccounts( {...displayedAccounts, accounts: sorted_accounts});
                  setSortedByLastName(true);
              }}
            }>{t('Last Name')}<img src={sort_icon} alt="sort icon" style={{width: '20px', height: 'auto', marginLeft: 2}} 
            /></li>
      <li className={css(accountListComponentStyles.header_row_item)}>{t('Email')}</li>
      <li className={css(accountListComponentStyles.header_row_item)}>{t('Phone Number')}</li>
      <li className={css(accountListComponentStyles.header_row_item)}>{t('Permissions')}</li>
    </ul>

    <div style={{height: '100%', overflowY: displayedAccounts.accounts.length > 4 ? 'scroll' : 'clip'}}>
    <ul className={css(accountListComponentStyles.account_list)}>
    {displayedAccounts.accounts.map((account) => {
  
            const selected = selectedAccount != null && (selectedAccount.id === account.id);
              
            return <li className={selected ? css(accountListComponentStyles.account_list_row_selected) : css(accountListComponentStyles.account_list_row)} 
                onClick={() => {
                account_controller.select_account(account.id, () => { setRecoil(modifyAccountModalAtom, true)})
                }}>
                <div className={css(accountListComponentStyles.account_list_row_item)}>{account.first_name}</div>
                <div className={css(accountListComponentStyles.account_list_row_item)}>{account.last_name}</div>
                <div className={css(accountListComponentStyles.account_list_row_item)}>{account.email}</div>
                <div className={css(accountListComponentStyles.account_list_row_item)}>{account.phone_number}</div>
                <div style={{padding: '0.5rem', width: '14rem', textAlign: 'center', fontWeight: 600, fontSize: '1rem', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center'}}>
                {account.permissions.manage_accounts && <p className={css(label_styles.label)}>{t('Admin')}</p>}
                {account.permissions.register_devices && <p className={css(label_styles.label)}>{t('Install')}</p>}
                {account.permissions.view_devices && <p className={css(label_styles.label)}>{t('View')}</p>}
                </div>
              </li>
            })
    }
    </ul>
        </div>
        </div>
    </article>


})